import React, { useContext, useEffect, useState } from "react";
import { VerifaiContext } from "../../context/ws/VerifaiProvider";

import { withLanding } from "../../hocs/withLanding";

import TBanner from "../../components/Banner";
import TService from "../../components/Service";
import THero from "../../components/Hero";
import TCarousel from "../../components/Carousel";
import { t } from "../../utilities/Message";
import { useHistory } from "react-router-dom";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { message } from "antd";

import TComingSoon from "../../components/ComingSoon";

import { useGetModels } from "../../hooks/useGetModels";
import { page_view } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import TCta from "../../components/Cta";
import TContactModal from "../../components/ForgotPasswordModal";
import "./styles.scss";

const recaptchaToken = `${process.env.REACT_APP_CAPTCHA}`;

const HomePage = () => {
  const { locale } = useContext(LanguageContext);
  const [contact, setContact] = useState({ showModal: false, loading: false });
  const messagesResponse = {
    error: t("app.Landing.CTA.MessageFailed"),
    success: t("app.Landing.CTA.Message"),
  };
  const [dataModels, loadingModels] = useGetModels();

  const motorsWithCaptcha =
    dataModels.length > 0 &&
    dataModels?.filter((motor) => motor?.pub_config?.captcha_verification);
  const history = useHistory();
  const { uploadData, uploadText, loading, error } = useContext(VerifaiContext);
  const [messageApi, contextHolder] = message.useMessage();

  const HeroProps = {
    title: t("app.Landing.Hero.Title"),
    description: t("app.Landing.Hero.Description"),
    image: "images/elevator_pitch.png",
    footer: t("app.Landing.Hero.Footer"),
  };

  const uploadFile = async (params: File) => {
    //Load the service , uploading in process
    const content_type = localStorage.getItem("typeFile"); //image,video
    if (content_type) {
      const algorithm_list = dataModels[content_type]
        .map((motor) => motor.id)
        .join(",");

      try {
        const recaptchaToken = ((await executeCaptcha()) || "test") as string;
        const response = await uploadData(
          params,
          algorithm_list,
          recaptchaToken
        );

        if (!response.id) return;
        if (response) {
          history.push(
            ROUTES_GENERAL.RESULT +
              "/" +
              response.id +
              "/" +
              response.guest_token
          );
        }
      } catch (e) {}
    }
    return;
  };

  const onSubmitCTA = () => {
    setContact({ ...contact, loading: true });
    messageApi.open({
      type: "success",
      content: messagesResponse.success,
    });
    setTimeout(() => {
      setContact({ ...contact, showModal: false, loading: false });
      return;
    }, 1000);
  };

  const executeCaptcha = async () => {
    const grecaptcha = (window as any).grecaptcha;
    return new Promise((resolve, reject) => {
      grecaptcha.ready(function () {
        grecaptcha
          .execute(recaptchaToken, {
            action: "post_file",
          })
          .then(function (token) {
            resolve(token);
          })
          .catch((error) => {
            console.error("Er ror en la ejecución de reCAPTCHA:", error);
            reject(error);
          });
      });
    });
  };

  useEffect(() => {
    if (error) {
      messageApi.destroy();
      messageApi.error(error.message);
    }
  }, [error, messageApi]);

  useEffect(() => {
    if (recaptchaToken) {
      const script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js?render=" + recaptchaToken;
      document.body.appendChild(script);
      script.onload = () => {
        const recaptchaBadge = document.querySelector(".grecaptcha-badge");
        const recaptchaContainer = document.getElementById(
          "recaptcha-container"
        );
        if (recaptchaBadge && recaptchaContainer) {
          recaptchaContainer.appendChild(recaptchaBadge);
          //@ts-ignore
          recaptchaBadge.style.visibility = "visible";
          //@ts-ignore
          recaptchaBadge.style.position = "static";
        }
      };
    }
    page_view({ page_type: EVENTS_TGM.home, locale });
  }, []);

  return (
    <div className="home-page">
      {contextHolder}
      <TCta onClick={() => setContact({ ...contact, showModal: true })} />
      <TBanner uploadFile={uploadFile} loading={loadingModels || loading} />
      <div id="recaptcha-container"></div> <THero {...HeroProps} />
      <TCarousel />
      <TService />
      <TComingSoon />
      {contact.showModal && (
        <TContactModal
          loading={contact.loading}
          visible={contact.showModal}
          onSubmit={onSubmitCTA}
          onCancel={() => setContact({ ...contact, showModal: false })}
        />
      )}
    </div>
  );
};

export default withLanding(HomePage);
